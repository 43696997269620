import { z } from "zod";
import {
  createLangChainSchema,
  deleteLangChainSchema,
  postLangChainFileSchema,
} from "./schema";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useGetAdminToken from "../../hooks/UseGetAdminToken";
import {
  createIndex,
  deleteIndexFile,
  getFilesName,
  postIndexFile,
} from "../../api/Admin/AdminIndex";
import { toast } from "react-toastify";
import { db } from "../../lib/firebase/firebase";
import { serverTimestamp } from "firebase/firestore";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Card, Button } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { MyAppBar } from "../../component/MyAppBar";
import { MuiFileInput } from "mui-file-input";

export type createIndexValue = z.infer<typeof createLangChainSchema>;
export type postIndexFileValue = z.infer<typeof postLangChainFileSchema>;
export type deleteIndexFileValue = z.infer<typeof deleteLangChainSchema>;

type FileData = {
  id: string;
  fileName: string;
  realFileName: string;
  createdAt: Date;
};
type IndexData = {
  id: string;
  createIndexName: string[];
  createdAt: Date;
};

const AdminIndex = () => {
  const [accessToken, setAccessToken] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [loading, setLoading] = useState(false);
  const [refetchFlg, setRefetchFlg] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedIndexFileName, setSelectedIndexFileName] = useState<string[]>(
    []
  );
  const [index, setIndex] = useState<IndexData | null>(null);

  useGetAdminToken();
  const toggleRefetch = () => setRefetchFlg((prev) => !prev);
  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      setAccessToken(token);
    }

    const fetchData = async () => {
      try {
        const data = await getFilesName();

        setFilesData(data as FileData[]);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    const fetchIndexName = async () => {
      const querySnapshot = await db.collection("indexfile").limit(1).get();

      const doc = querySnapshot.docs[0];
      const data = {
        id: doc.id,
        ...doc.data(),
      } as IndexData;

      setIndex(data);

      setIndex(data as IndexData);
    };
    fetchIndexName();
    fetchData();
  }, [refetchFlg]);

  const handleFileSelect = (selectedIds: any) => {
    const selectedFiles = filesData.filter((file) =>
      selectedIds.includes(file.id)
    );
    setSelectedFiles(selectedFiles.map((file) => file.realFileName));
    setSelectedIndexFileName(selectedFiles.map((file) => file.fileName));
  };
  const handleChange = (file: any) => {
    setFileName(file.name);
    setFile(file);
  };

  const deleteSelectedFiles = () => {
    if (selectedFiles.length > 0) {
      if (window.confirm("選択したファイルを削除しますか？")) {
        deleteIndexMutation.mutate({ accessToken, fileName: selectedFiles });
      }
    } else {
      toast.info("削除するファイルを選択してください。");
    }
  };

  const createSelectedFiles = () => {
    if (selectedFiles.length > 0) {
      if (
        window.confirm(
          "選択したファイルからインデックスを生成しますか？過去のデータは上書きされます。"
        )
      ) {
        createIndexMutation.mutate({ accessToken, fileName: selectedFiles });
      }
    } else {
      toast.info("インデックス生成するファイルを選択してください。");
    }
  };

  const createIndexMutation = useMutation<string, Error, createIndexValue>({
    mutationFn: async () => {
      setLoading(true);

      if (!accessToken) {
        toast.error("インデックス生成に失敗しました。");
      }
      if (selectedFiles.length === 0) {
        toast.error("ファイルが選択されていません。");
      }

      try {
        const response = await createIndex(accessToken, selectedFiles);

        await db.collection("indexfile").doc(index?.id).update({
          createIndexName: selectedIndexFileName,
          createdAt: serverTimestamp(),
        });

        toggleRefetch();
        toast.success("インデックスが正常に生成されました。");
        return response;
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteIndexMutation = useMutation<string, Error, deleteIndexFileValue>({
    mutationFn: async (data) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("ファイル削除に失敗しました。");
      }

      try {
        const response = await deleteIndexFile(accessToken, data.fileName);

        for (const filename of data.fileName) {
          const querySnapshot = await db
            .collection("files")
            .where("realFileName", "==", filename)
            .get();

          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        }

        toast.success("ファイル削除に成功しました。");
        window.location.reload();
        toggleRefetch();
        return response;
      } catch (error) {
        toast.error("ファイル削除に失敗しました。");
        throw error;
      } finally {
        setLoading(false);
      }
    },
  });

  const postIndexFileMutation = useMutation<string, Error, postIndexFileValue>({
    mutationFn: async () => {
      setLoading(true);

      if (!accessToken) {
        toast.error("ファイル送信に失敗しました。");
        setLoading(false);
        return;
      }

      if (!file) {
        toast.error("ファイルが選択されていません。");
        setLoading(false);
        return;
      }

      try {
        const response = await postIndexFile(accessToken, file);

        await db.collection("files").add({
          fileName,
          realFileName: response,
          createdAt: serverTimestamp(),
        });
        setFile(null);
        toggleRefetch();
        toast.success("ファイルが正常に送信されました。");
        return response;
      } catch (error) {
        toast.error("ファイル送信に失敗しました。");
        throw error;
      } finally {
        setLoading(false);
      }
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Button onClick={deleteSelectedFiles} disabled={loading}>
          選択したファイルを削除
        </Button>

        <Button
          type="submit"
          onClick={createSelectedFiles}
          disabled={loading}
          sx={{ marginLeft: "40px" }}
        >
          {loading ? "Loading..." : "インデックス生成"}
        </Button>
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: "fileName", headerName: "ファイル名", flex: 2 },
    {
      field: "createdAt",
      type: "dateTime",
      headerName: "アップロード日時",
      flex: 1,
    },
  ];

  return (
    <div className="mt-[72px]">
      <MyAppBar title="参照ファイル管理" />
      <div>
        <aside
          aria-controls="menu"
          id="menu"
          className="fixed top-[72px] left-0 hidden md:block md:w-[240px]"
        >
          <div className="flex flex-col font-semibold my-3">
            <Link
              to="/admin/home"
              className="flex items-center gap-3 p-2 bg-white hover:bg-bgAllGray text-adminLink"
            >
              <FontAwesomeIcon icon={faUser} />
              <span>ユーザー管理</span>
            </Link>
            <Link
              to="/admin/index"
              className="flex items-center gap-3 p-2 bg-bgBlue text-white"
            >
              <FontAwesomeIcon icon={faDatabase} />
              <span>参照ファイル管理</span>
            </Link>
          </div>
        </aside>
        <main className="ml-0 md:ml-[240px] p-4">
          <Card className="mt-3 p-5">
            <h2 className="text-xl font-bold mb-3">ファイルアップロード</h2>
            <p className="mb-3">
              参照ファイルをアップロードしてください。
              <br />
              対応ファイル形式： .txt、.docx、.pdf、.csv、.json
            </p>

            <p>
              <MuiFileInput
                size="small"
                variant="outlined"
                value={file}
                onChange={handleChange}
                InputProps={{
                  inputProps: { accept: ".txt, .docx,.pdf,.csv,.json" },
                  startAdornment: <AttachFileIcon />,
                }}
                placeholder="ファイルを選択"
              />
            </p>
            <button
              onClick={() =>
                postIndexFileMutation.mutate({ accessToken, file })
              }
              className="mt-3 p-3 rounded-md bg-bgBlue text-white shadow-md"
            >
              アップロード
            </button>
          </Card>

          <Card className="mt-6 p-5">
            <h2 className="text-xl font-bold mb-3">
              現在のインデックスの構成ファイル一覧
            </h2>
            <ul>
              {index && index.createIndexName.length > 0 && (
                <li>{index.createIndexName.join(" / ")}</li>
              )}
            </ul>
            <hr className="my-5" />
            <h2 className="text-xl font-bold mb-3">
              アップロード済みファイル一覧
            </h2>
            <p className="mb-3">
              アップロードされた全てのファイルから、選択参照がついているファイルをまとめて、参照用のインデックスを生成します。過去のデータは上書きされます。
            </p>

            <DataGrid
              rows={filesData}
              columns={columns}
              getRowId={(row) => row.id.replace(/\s+/g, "_")}
              onRowSelectionModelChange={(newSelectionModel) =>
                handleFileSelect(newSelectionModel)
              }
              checkboxSelection
              slots={{ toolbar: CustomToolbar }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
            />
          </Card>
        </main>
      </div>
    </div>
  );
};

export default AdminIndex;
