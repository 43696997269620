import { z } from "zod";
import { AuthGuard } from "../../feature/auth/component/AuthGuard/AuthGuard";
import {
  homeSchema,
  postServiceSchema,
  postLangChainSchema,
  deleteServiceSchema,
} from "./schema";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { postQuestion } from "../../api/postQuestion";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../lib/firebase/firebase";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { useAuthContext } from "../../feature/auth/provider/AuthProvider";
import Chat from "./Chat/Chat";
import {
  deleteServiceMutate,
  postServiceMutate,
} from "../../api/ServiceDescription/ServiceDescription";
import { Service } from "../../types/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export type FormValues = z.infer<typeof homeSchema>;
export type postServiceValue = z.infer<typeof postServiceSchema>;
export type deleteServiceValue = z.infer<typeof deleteServiceSchema>;
export type postLangChainValue = z.infer<typeof postLangChainSchema>;
const Home = () => {
  const [messages, setMessages] = useState<Service[]>([]);

  const navigation = useNavigate();
  const { user } = useAuthContext();
  const [disabledFlg, setDisabledFlg] = useState<boolean>(false);
  const [deleteFlg, setDeleteFlg] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const mutation = useMutation<string, Error, postLangChainValue>({
    mutationFn: (data) => {
      if (!accessToken) {
        throw new Error("Access token is undefined");
      }
      return postQuestion(data.question, accessToken);
    },
  });
  const serviceMutation = useMutation<string, Error, postServiceValue>({
    mutationFn: (data) => {
      if (!accessToken && !user?.uid) {
        throw new Error("Access token is undefined");
      }
      return postServiceMutate(data.text, accessToken, user?.uid, data.sender);
    },
  });

  const deleteServicesMutation = useMutation<string, Error, deleteServiceValue>(
    {
      mutationFn: () => {
        if (!accessToken) {
          throw new Error("Access token is undefined");
        }
        if (!user?.uid) {
          throw new Error("User UID is undefined");
        }
        return deleteServiceMutate(accessToken, user.uid);
      },
    }
  );

  const handleDelete = async () => {
    const delConfirm = window.confirm(
      "すべての履歴を削除します。\n本当によろしいですか？"
    );
    if (delConfirm) {
      setDeleteFlg(true);
      try {
        await deleteServicesMutation.mutateAsync({
          accessToken: accessToken,
          uid: user?.uid,
        });
        toast.success("削除に成功しました");
        setDeleteFlg(false);
      } catch (e) {
        toast.error("削除に失敗しました");
        setDeleteFlg(false);
      }
    }
  };

  const [accessToken, setAccessToken] = useState<string>("");
  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        const token = await user.getIdToken();
        setAccessToken(token);
      }
    };

    fetchToken();

    if (user) {
      const unsubscribe = db
        .collection("services")
        .where("uid", "==", user.uid)
        .orderBy("createdAt", "asc")
        .onSnapshot((snapshot) => {
          const servicesData: Service[] = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              text: data.text,
              sender: data.sender,
            };
          });
          setMessages(servicesData);
          setTimeout(() => {
            const answerScrollTop = scrollRef?.current
              ? scrollRef.current.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top
              : 0;

            window.scrollTo({
              behavior: "smooth",
              top: answerScrollTop,
            });
          }, 100);
        });

      return () => unsubscribe();
    }
  }, [user]);

  const { register, handleSubmit, reset } = useForm<FormValues>({
    resolver: zodResolver(homeSchema),
  });
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setDisabledFlg(true);

    try {
      await db.collection("services").add({
        text: data.question,
        uid: user?.uid,
        sender: "user",
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });
    } catch (error) {
      setDisabledFlg(false);
      console.error("Firestoreへの保存中にエラーが発生しました", error);
      toast.error("データの保存に失敗しました。");
      return;
    }

    const response = await mutation.mutateAsync({
      accessToken: accessToken,
      question: data.question,
    });

    await serviceMutation.mutateAsync({
      text: response,
      accessToken: accessToken,
      uid: user?.uid,
      sender: "bot",
    });

    setDisabledFlg(false);
    reset();
  };

  const handleSignOut = async () => {
    const confirm = window.confirm(
      "ログアウトします。\n本当によろしいですか？"
    );
    if (confirm) {
      try {
        await signOut(auth);
        toast.success("ログアウトしました。");
        navigation("/");
      } catch (e) {
        toast.error("ログアウトに失敗しました。");
      }
    } else {
      return;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit);
    } else {
      return;
    }
  };

  return (
    <AuthGuard>
      <div className="h-screen w-full flex flex-col font-MPLUS1p">
        <div className="fixed left-0 top-0 w-full z-20">
          <div className="bg-bgBlue flex items-center md:h-20 gap-[0.625rem] p-2 md:p-4">
            <button
              onClick={handleSignOut}
              className="bg-white rounded-full flex items-center justify-center p-2"
            >
              <img src="home.png" alt="ホームアイコン" className="w-10" />
            </button>
            <h1 className="flex font-extrabold text-white text-[1.5rem] leading-none md:pl-6">
              さすガねっと&nbsp;&nbsp;&nbsp;サービス説明 AIボット
            </h1>
          </div>
          <div className="bg-bgGray flex flex-col-reverse md:flex-row md:h-[5.315rem] gap-6 items-center justify-between p-4">
            <button
              onClick={handleDelete}
              disabled={deleteFlg}
              className={
                deleteFlg
                  ? "block w-[93vw] md:w-[25vw] h-14 flex justify-center items-center gap-2.5 rounded-lg bg-bgBlue text-white text-xl font-bold shadow-md px-6 py-2 grayscale"
                  : "block w-[93vw] md:w-[25vw] h-14 flex justify-center items-center gap-2.5 rounded-lg bg-bgBlue text-white text-xl font-bold shadow-md px-6 py-2"
              }
            >
              {deleteFlg ? (
                <>
                  <span>削除中…</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faTrash} />
                  <span>履歴を削除</span>
                </>
              )}
            </button>
            <Link
              to="/recommend"
              className="block rounded-lg bg-white shadow-md w-[93vw] md:w-[45vw] p-2 px-4 flex items-center justify-center gap-2.5 h-14"
            >
              <img src="memoIcon.png" alt="メモアイコン" className="w-[40px]" />
              <span className="text-orange text-xl font-extrabold">
                最適プラン レコメンド
              </span>
            </Link>
          </div>
        </div>

        <div className="relative mt-[165px] pb-[125px] bg-bgAllGray flex-1">
          {deleteFlg && (
            <div className="fixed w-full h-screen mt-[-105px] flex justify-center items-center bg-bgAllGray bg-opacity-70 text-align z-10">
              <p className="text-xl font-extrabold">履歴を削除中……</p>
            </div>
          )}

          <Chat messages={messages} />

          {disabledFlg && (
            <div className="flex bg-questionBg items-start p-4 border-b border-borderGray shadow">
              <div>
                <img
                  src="chatgpt.png"
                  alt="OpenAIアイコン"
                  className="w-[52px]"
                />
              </div>
              <div className="flex-1 text-lg font-medium text-borderGray pl-5 py-3">
                AI回答中……
              </div>
            </div>
          )}
          <div ref={scrollRef} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="fixed left-0 bottom-0 w-full"
        >
          <div className="p-4 bg-white border-t border-borderGray">
            <div className="relative w-full">
              <input
                type="text"
                {...register("question")}
                placeholder="質問は、サービスやプランの名称、条件などを明確に入力してください"
                className={
                  disabledFlg
                    ? "bg-bgGray px-4 py-6 w-full shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg appearance-none resize-none outline-none"
                    : "bg-white px-4 py-6 w-full shadow-[0px_1px_7px_3px_rgba(0,0,0,0.2)] rounded-lg appearance-none resize-none outline-none"
                }
                onKeyDown={handleKeyDown}
                required
                disabled={disabledFlg}
              />
              <button
                type="submit"
                className={
                  disabledFlg
                    ? "absolute inset-y-0 right-4 w-[52px] grayscale"
                    : "absolute inset-y-0 right-4 w-[52px]"
                }
                disabled={disabledFlg}
              >
                <img src="plane.png" alt="送信アイコン" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </AuthGuard>
  );
};
export default Home;
