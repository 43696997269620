import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdminLoginSchema } from "./schema";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import AuthLoadingButton from "../../component/atoms/LoadingButton/AuthLoadingButton";
import AuthButton from "../../component/atoms/Button/AuthButton";
import { useMutation } from "@tanstack/react-query";
import { LoginMutate } from "../../api/Admin/AdminLogin";

export type FormValues = z.infer<typeof AdminLoginSchema>;

const AdminLogin = () => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const mutation = useMutation<string, Error, FormValues>({
    mutationFn: async (data) => {
      return await LoginMutate(data.email, data.password);
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(AdminLoginSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      const response = await mutation.mutateAsync(data);

      localStorage.setItem("admin_token", response);
      toast.success("ログインに成功しました。");
      setIsLoading(false);
      navigation("/admin/home");
      reset();
    } catch (e) {
      toast.error("ログインに失敗しました。");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-screen flex justify-center items-center bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md bg-white p-8 shadow-md">
          <h1 className="text-2xl font-bold mb-4 text-center">
            大阪ガス ChatBot 管理画面
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-600 mb-2"
                htmlFor="username"
              >
                メールアドレス
              </label>
              <input
                {...register("email")}
                type="email"
                placeholder="email"
                className="w-full p-2 border rounded-md"
              />
              {errors.email && (
                <p className="text-errorText">{errors.email.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-600 mb-2"
                htmlFor="password"
              >
                パスワード
              </label>
              <input
                {...register("password")}
                type="password"
                placeholder="password"
                className="w-full p-2 border rounded-md"
              />
              {errors.password && (
                <p className="text-errorText">{errors.password.message}</p>
              )}
            </div>
            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="w-full pt-4 rounded-md bg-blue-500 hover:bg-blue-600 disabled:bg-blue-300"
              >
                {isLoading ? (
                  <AuthLoadingButton />
                ) : (
                  <AuthButton content="ログイン" />
                )}
              </button>
            </div>
          </form>

          <div className="mt-2">
            <Link to={"/admin/reminder"}>パスワードを忘れた</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
